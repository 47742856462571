import React from 'react';
import './styles/MainVideoReel.css';
import { motion } from "framer-motion"

const variants = {
    active: {"opacity": 1},
    inactive: {"opacity": 0}
}

function isActive(id, event){
  
    let scrollDistance = window.pageYOffset
    let rect = document.getElementById(id).getBoundingClientRect()

    if(rect.top -120 <= scrollDistance && rect.bottom - (window.innerHeight * 0.55) >= scrollDistance){
        return "active"
    } else {
        return "inactive"
    }

}

class MainVideoReel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {active: "active"};
    }

    render(){return <motion.div 
            className='MainVideoReelContainer'
            id={this.props.id}
            variants={variants}
            animate={this.state.active}
            transition={{duration: 1}}
        >
        <video id="reel" src="./videos/reel.mp4" autoPlay loop muted></video>
    </motion.div>
    }

    componentDidMount(){
        document.addEventListener("scroll", (e) => { 
            this.setState({ active: isActive(this.props.id, e) })
        })
    }

    componentWillUnmount(){
        document.addEventListener("scroll", (e) => { 
            this.setState({ active: isActive(this.props.id, e) })
        })
    }

}

export default MainVideoReel
