import React from "react";
import "./styles/MainPage.css";
import MainVideoReel from "../components/MainVideoReel";
import ContentSection from "../components/ContentSection";
import WeCreateSection from "../components/WeCreateSection";
import WhatWeDoSection from "../components/WhatWeDoSection";
import OurClientsSection from "../components/OurClientsSection";
import ContactUsSection from "../components/ContactUsSection";
import BackgroundNoise from "../components/BackgroundNoise";


class MainPage extends React.Component {
  
  render() {
    return (
      <>
        <BackgroundNoise></BackgroundNoise>
        <MainVideoReel id="MainVideoReel"></MainVideoReel>
        <div className="ContentSections">
          <ContentSection id="services" heading="ABOUT US">
            <WhatWeDoSection></WhatWeDoSection>
          </ContentSection>
          <ContentSection id="work" heading="WORK">
            <WeCreateSection></WeCreateSection>
          </ContentSection>
          <ContentSection id="clients" heading="CLIENTS">
            <OurClientsSection></OurClientsSection>
          </ContentSection>
          <ContentSection id="contact" heading="CONTACT">
            <ContactUsSection></ContactUsSection>
          </ContentSection>
        </div>
      </>
    );
  }
}

export default MainPage;
