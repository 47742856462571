import React from 'react';
import './styles/WeCreateSection.css';
import { motion, AnimatePresence } from "framer-motion"
import SampleVideo from './SampleVideo';

const url = 'https://virtualconferences.com.au/vimeo/'

class WeCreateSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {featureVideosData: []};
    }

    render(){
    
    return <>

    <motion.div 
        className='WeCreateSectionContainer'
        style={{gridTemplateColumns: "repeat(auto-fit, minmax(450px, 500px))"}}>
        
        {
        this.state.featureVideosData.length > 0 ? 
        <AnimatePresence>
        { 
            this.state.featureVideosData.map((element, iter) => {
                return <SampleVideo videoName={element[0]} videoSrc={element[1]}></SampleVideo>  
            }) 
        }
        </AnimatePresence>
        : 
        <motion.div
            className='LoadingText'
            transition={{loop: Infinity, duration: 4}}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
        Loading Content, <br/> Please Wait a Moment
        </motion.div>
        } 
    </motion.div>
    </>
    }

    getVideosFromVimeo(){

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: 'application/json'
            }
        })
        .then(
            response => response.json()
        )
        .then(
            data => {

                    this.setState({featureVideosData: data.data});

                }
        )
        .catch(
          error => console.error(error)
        )
    }

    componentDidMount(){

        this.getVideosFromVimeo()

    }

    componentWillUnmount(){}

}

export default WeCreateSection