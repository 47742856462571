import React from 'react';
import "./styles/ContactUsSection.css"

class ContactUsSection extends React.Component{

    render(){
        return <div className='ContactUsSectionContainer'>
            <div className="ContactUsSectionDetails">
                <div className='ContactUsSectionImage'>
                    <img alt="amanda-pic" src='./amanda.gif'></img>
                </div>
                <div className='ContactUsSectionText'>
                    <p>HAVE AN IDEA? CONTACT US!</p>
                    <p>CALL: <span >(08) 8155 4700</span></p>
                    <p>EMAIL: <a className='clickable' href="mailto:admin@tav.com.au">admin@tav.com.au</a></p>
                </div>
            </div>
            <iframe className='ContactUsMap' title="location-map" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJZZ5hK7vLsGoRKGT4S51udSg&key=AIzaSyB55QeCN0RD1UqpV1fTP5X9BElb92dymwo"></iframe>
        </div>
    }

}

export default ContactUsSection