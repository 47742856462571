import React from 'react';
import './styles/SampleVideo.css';
import { motion} from "framer-motion"

async function playMousedVideo(e){
 
    try {
      await e.play()
    } catch (err) {
        console.log(err)
    }

}

async function pauseMousedVideo(e){

    try {
      await e.pause()
    } catch (err) {
    }

}

const textVariant = {
    mouseLeave: { opacity: 1, "pointer-events": "auto"},
    mouseEnter: { opacity: 0, "pointer-events": "none"},
}

class SampleVideo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mouseOver: "mouseLeave", playing: false};
    }

    /*
    
    <video 
                        className='DesktopVideo' 
                        src={this.props.videoSrc}
                        onClick={(e) => this.click(e.target)}
                        >
                    </video>
    
    */

    render(){return <>{ 
           <motion.div 
                            key={this.props.videoName + " - video"} 
                            className='SubVideoContainers clickable'
                            onMouseEnter={(e) => this.mouseEnter()}  
                            onMouseLeave={(e) => this.mouseLeave()}
                            whileInView={{opacity: 1}}
                            initial={{opacity: 0}}
                            exit={{opacity: 0}}
                            transition={{duration: 3}}
                    >
                     <video 
                        className='DesktopVideo' 
                        onClick={(e) => this.click(e.target)}

                    >
                        <source
                            type="video/mp4" src={this.props.videoSrc}
                        />
                    </video>
                    <motion.div 
                        className='DesktopVideoSubtitles' 
                        initial={this.state.mouseOver}
                        animate={this.state.mouseOver}
                        transition={{duration: 1}}
                        variants={textVariant}
                        >
                        {this.props.videoName}
                    </motion.div>
            </motion.div>}</>
        
    }

    mouseEnter(){
        this.setState({mouseOver: "mouseEnter", playing: this.state.playing})
    }

    mouseLeave(){
        this.setState({mouseOver: "mouseLeave", playing: this.state.playing})
    }

    click(e){
        
        if(this.state.playing){
            this.setState({mouseOver: this.state.mouseOver, playing: false})
            pauseMousedVideo(e)
        }else{
            this.setState({mouseOver: this.state.mouseOver, playing: true})
            playMousedVideo(e)
        }
    }

}

export default SampleVideo